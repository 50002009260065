<template>
  <div>
    <ManageUser />
  </div>
</template>
<script>
import ManageUser from "@/components/User/ManageUser";
export default {
  components: {
    ManageUser,
  },
  created() {
  },
};
</script>